import { graphql, Link } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { PitchesQuery } from 'src/graphqlTypes';

interface PitchesProps {
    data: PitchesQuery;
}

const Pitches: FC<PitchesProps> = ({ data }) => {
    return (
        <Layout>
            {data.allContentfulPitch.nodes.map((pitch) => (
                <div key={pitch.id}>
                    <Link to={`/${pitch.slug}`}>{pitch.pitchTitle}</Link>
                </div>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Pitches {
        allContentfulPitch {
            nodes {
                id
                slug
                pitchTitle
            }
        }
    }
`;

export default Pitches;
